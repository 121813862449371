<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>企业管理</a-breadcrumb-item>
            <a-breadcrumb-item>模块列表</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-box" style='min-height:500px'>
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <div v-for="(value, key) in list" :key='key' class="appModule">
                <div style="margin:15px 0">{{key}}</div>
                <a-row :gutter="[16,16]">
                    <a-col v-for='item in value' :key='item.id' :span="4">
                        <a-card :class="item.app_is_enabled == 0?'appModule-card':''" hoverable>
                            <LImg :src="item.app_icon" style="width: 50px; height: 50px;"/>
                            <span style="margin-left:20px">{{item.app_name}}</span>
                            <div class="appModule-icon">
                                <a-dropdown placement="bottomCenter">
                                    <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                                        <a-icon type="ellipsis" />
                                    </a>
                                    <a-menu slot="overlay">
                                        <a-menu-item @click="toOpen(item.app_id)" v-show='item.app_is_enabled == 0'>
                                            开启
                                        </a-menu-item>
                                        <a-menu-item @click="toClose(item.app_id)" v-show='item.app_is_enabled == 1'>
                                            禁用
                                        </a-menu-item>
                                    </a-menu>
                                </a-dropdown>
                            </div>
                        </a-card>
                    </a-col>
                </a-row>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:"appModule",
        data() {
            return {
                loading:false,
                list: []
            }
        },
        created () {
            this.getList()
        },
        methods: {
            async getList() {
                this.loading = true
                let res = await this.$store.dispatch('enterpriseCompanyModuleAction', { data: {wid:this.$route.params.id} })
                this.list = res.data.module
                this.loading = false
            },
            toOpen(id){
                let that = this
                this.$confirm({
                    title: `您确定开启该应用？`,
                    okText: '确定',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                    return new Promise(async (resolve, reject) => {
                        let res = await that.$store.dispatch('enterpriseCompanyEnableModuleAction', { data: {wid:that.$route.params.id,module_id:id} })
                        if (res) {
                        that.getList()
                        that.$message.success('操作成功~')
                        resolve(res)
                        }
                    }).catch(error => console.log(error))
                    }
                })
            },
            toClose(id){

                let that = this
                this.$confirm({
                    title: `您确定禁用该应用？`,
                    okText: '确定',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                    return new Promise(async (resolve, reject) => {
                        let res = await that.$store.dispatch('enterpriseCompanyDisableModuleAction', { data: {wid:that.$route.params.id,module_id:id} })
                        if (res) {
                        that.getList()
                        that.$message.success('操作成功~')
                        resolve(res)
                        }
                    }).catch(error => console.log(error))
                    }
                })
            }
        },
    }
</script>

<style lang="scss">
    .appModule{
        position: relative;
        &-icon{
            position: absolute;
            right: 12px;
            top:0px;
        }
        &-card{
            background-color: #eee;
        }
        .ant-card-body{
            padding: 12px;
        }
    }
</style>